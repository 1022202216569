
import { Vue, Component, Watch } from "vue-property-decorator";
import SyllabellAppIcon from "@/components/SyllabellAppIcon.vue";
import BitvilleButton from "@/components/BitvilleButton.vue";
import Routes from "@/Routes";
import AuthStore from "@/store/AuthStore";
import AppEvents from "@/AppEvents";

@Component({
  name: "AppBar",
  components: {
    SyllabellAppIcon,
    BitvilleButton
  }
})
export default class AppBar extends Vue {
  
  get isAuthenticated(): boolean {
    return AuthStore.isAuthenticated;
  }
  
  onLoginButtonClick() {
    if (this.isAuthenticated){
      this.$root.$emit(AppEvents.REDIRECT_TO_FRONTEND);
    } else {
      this.$router.push({ 
        name: Routes.signin.name
      });
    }
  }
}
