
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "SyllabellAppIcon",
  components: {},
})
export default class SyllabellAppIcon extends Vue {
  @Prop({ default: true })
  gradient!: boolean;

  @Prop({ default: false })
  fillHeight!: boolean;
}
