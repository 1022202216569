
import { Vue, Prop, Component } from "vue-property-decorator";

type buttonColor = 
'green' 
| 'blue' 
| 'blue-white' 
| 'green-white'
| 'dark-grey' 
| 'light-grey' 
| 'red-light-grey'
| 'grey-transparent'
| 'blue-transparent';

type sizeType = '' | 'xsmall' | 'small'

@Component({
  name: "BitvilleButton",
})
export default class BitvilleButton extends Vue {
  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: false })
  pulsing!: boolean;

  @Prop({ default: false })
  size!: sizeType;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  formButton!: boolean;

  @Prop({ default: "blue" })
  color!: buttonColor;

  @Prop({ default: "Button" })
  text!: string;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop({ default: false })
  keepHover!: boolean;

  @Prop({ default: false })
  fullyTransparent!: boolean;

  onButtonClick() {
    this.$emit("buttonClick");
  }
}
