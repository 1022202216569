
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthStore from './store/AuthStore';
import PageLoading from "@/components/PageLoading.vue";
import Snackbar from "@/components/Snackbar.vue";
import AppEvents from './AppEvents';
import AuthService from './services/AuthService';
import AppBar from "@/components/AppBar.vue";

@Component({
  components: {
    PageLoading,
    Snackbar,
    AppBar
  },
 
})
export default class App extends Vue {
  loading = false;
  showPageLoading = true;
  loadingText = "";

  @Watch('isAuthenticated', { immediate: true })
  async onIsAuthenticatedChange(newVal: boolean) {
    if (newVal) {
      if (window.location.pathname.startsWith("/legal")) {
        // console.log("Current route has disable automatic post-login redirection, staying on current route.")
      } else {
        await this.redirectToFrontend();
      }
    }
  }

  @Watch('authCheckInProgress')
  async onAuthCheckInProgressChange(newVal: boolean) {
    if (!newVal && (!this.isAuthenticated || window.location.pathname.startsWith("/legal"))) {
      this.showPageLoading = false;
    }
  }

  get isAuthenticated(): boolean {
    return AuthStore.isAuthenticated;
  }

  get authCheckInProgress(): boolean {
    return this.msalRedirectPromiseInProgress || this.googleRedirectPromiseInProgress;
  }

  get msalRedirectPromiseInProgress(): boolean {
    return AuthStore.msalRedirectPromiseInProgress;
  }

  get googleRedirectPromiseInProgress(): boolean {
    return AuthStore.googleRedirectPromiseInProgress;
  }
  
  async redirectToFrontend() {
    console.log('redirecting...');
    this.loadingText = "Signing in...";

    const success = await AuthStore.getFrontendAccess();
    if (!success) {
      this.showPageLoading = false;
      return;
    }
    this.showPageLoading = true;
    const currentWindowLocation = window.location.origin;
    const redirectUrl = this.$route.query.redirectUrl;
    if (redirectUrl && !this.isFileUrl(redirectUrl.toString())) {
      (window as any).location.href = currentWindowLocation + redirectUrl;
    } else {
      (window as any).location.href = currentWindowLocation + "/app";
    }
  }

  async created() {
    this.$root.$on(AppEvents.REDIRECT_TO_FRONTEND, await this.redirectToFrontend);
    this.$root.$on(AppEvents.SIGNOUT_FROM_APP, await this.signoutFromApp);

    if ((!this.isAuthenticated || window.location.pathname.startsWith("/legal")) && !this.authCheckInProgress) {
      this.showPageLoading = false;
    }

    // if (AuthStore.isCustomAuthenticated && !AuthService.isAccessTokenValid()) {
    //   AuthService.signoutFromApp()
    // }
  }

  beforeDestroy() {
    this.$root.$off(AppEvents.REDIRECT_TO_FRONTEND, this.redirectToFrontend);
    this.$root.$off(AppEvents.SIGNOUT_FROM_APP, this.signoutFromApp);
  }

  isFileUrl(url: string): boolean {
    let lastUrlPart = url.split('/').pop();
    return lastUrlPart ? lastUrlPart.indexOf('.') > -1 : false;
  }

  async signoutFromApp() {
    await AuthStore.resetFrontendAccess();

    if (AuthStore.isMsalAuthenticated) {
      await this.$msal.signOut();
    }

    if (AuthStore.isGoogleAuthenticated) {
      await this.$firebaseAuth.signOutWithGoogle();
    }

    if (AuthStore.isCustomAuthenticated) {
      await AuthStore.resetAuthTokens();
    }

    (window as any).location.href = window.location.origin;
  }

  get showAppBar(): boolean {
    return this.$route.matched.some(r => !!r.meta && !!r.meta.showAppBar);
  }
}

